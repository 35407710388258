<template>
  <div class="w-660 h-80 row">
    <div class="bgbox"></div>
    <div class="allbox dp-f ai-c jc-sb">
      <div class="dp-f ai-c">
        <!-- <el-image class="img w-44 h-44 ml-24" :src="require('@/assets/img/settings/'+props.iconString+'.png')" />\ -->
        <div class="imgbox w-44 h-44 dp-fc ml-24">
          <span class="icon iconfont dp-fc fs-52" :class="'icon-' + props.iconString"></span>
        </div>
        <p class="ml-21">{{ props.title }}</p>
      </div>
      <el-switch v-model="state.data" class="mr-20" @change="dataChange" :disabled="props.disabled" />
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineEmits } from "vue";
const emit = defineEmits(['Emit'])
const props = defineProps({
  title: {
    //当前步骤下标
    type: String,
    default: () => {
      return '标题'
    }
  },
  iconString: {
    //当前步骤下标
    type: String,
    default: () => {
      return 'note'
    }
  },
  data: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  disabled: {
    type: Boolean,
    default: () => {
      return false
    }
  },
});
let state = reactive({
  data: false,
});
state.data = props.data
const dataChange = ((value) => {
  emit('Emit', value)
})
</script>
<style lang="scss" scoped>
@import '@/styles/color/value.scss';

.row {
  position: relative;

  .bgbox {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--active-theme);
    opacity: 0.05;
    z-index: 1;
  }

  .allbox {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .imgbox {
    background-color: $active-alpha;
  }
}

::v-deep .el-switch.is-checked .el-switch__core {
  background-color: var(--active-theme);
  border-color: var(--active-theme);
}</style>