<template>
  <div class="ml-30 t-20">
    <settingItem title="短信验证码" class="mt-20" :data="state.token" @Emit="tokenSet"></settingItem>
    <settingItem title="ukey证书" iconString="certificate" class="mt-20" :data="state.token" @Emit="tokenSet">
    </settingItem>
    <settingItem title="密码验证" iconString="password" class="mt-20" :data="state.token" @Emit="tokenSet"></settingItem>
    <!-- <settingItem title="人脸识别验证" iconString="humanFace" class="mt-20" :data="state.token" @Emit="tokenSet"></settingItem> -->
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, nextTick } from "vue";
import settingItem from "./settingItem.vue"
let state = reactive({
  token: true,
});
// 短信验证码
const tokenSet = ((value) => {

})
</script>

<style lang="scss" scoped>

</style>